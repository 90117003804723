import React from 'react'
import { Metadata } from '@classy/campaign-page-blocks'
import { getIntelligentAskAmount } from 'services/intelligentAskAmount/intelligent-ask-amount'
import { EVENT } from 'models/event'
import { HeapCookie } from 'services/analytics'

/**
 * Custom React hook that manages fetching and dispatching Intelligent Ask Amounts.
 */

export const useIntelligentAskAmount = (metadata?: Metadata, heapCookie?: HeapCookie) => {
  const intelligentAskAmounts = React.useCallback(async () => {
    if (!metadata?.campaignId) return

    const iaaBaseAmounts = await getIntelligentAskAmount(metadata.campaignId, heapCookie?.userId)
    document.dispatchEvent(
      new CustomEvent(EVENT.IAA_FETCH_AMOUNTS, {
        detail: {
          ...iaaBaseAmounts,
          isLoading: false,
        },
      }),
    )
  }, [metadata?.campaignId, heapCookie?.userId])

  React.useEffect(() => {
    if (
      metadata?.intelligentAskAmount?.onetimeEnabled ||
      metadata?.intelligentAskAmount?.recurringEnabled
    ) {
      intelligentAskAmounts()
    }
  }, [metadata?.intelligentAskAmount, heapCookie?.userId, intelligentAskAmounts])
}
