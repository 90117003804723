import React from 'react'
import type { NextPage, GetServerSideProps, GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { usePathname } from 'next/navigation'
import { ParsedUrlQuery } from 'querystring'
import type {
  ActivityFeedProps,
  DonationProps,
  LayoutProps,
  Metadata,
  ProgramDesignation,
} from '@classy/campaign-page-blocks'
import { CampaignPage, CampaignPageProps } from 'components/CampaignPage'
import { OpenGraphTags } from 'components/OpenGraphTags'
import { PAGE_TYPES, PageConfig } from 'models/pages'
import { getMockData, getMockPageConfig } from 'services/mock-data'
import {
  findCampaignPage,
  denormalizeLayoutWithBlocks,
  getCampaignData,
  getCampaignPagesData,
  getLegacyCampaignData,
  isCampaignInactive,
} from 'services/campaign'
import { buildSocialShareLinks } from 'features/Block/social-links'
import { getOrganizationData, getOrganizationTags } from 'services/organization'
import { domainMaskRedirect, getDomainFromRequest, getPrimaryDomain } from 'services/domain'
import { Channel } from 'services/analytics/integrationChannels/integrationChannels.model'
import { getOrganizationChannels } from 'services/analytics/integrationChannels/integrationChannels'
import { getCheckoutQueryParamsToForward } from 'features/Block/donation/getCheckoutQueryParamsToForward'
import { getPageMetaDescription, getPageTitle } from 'utils/head'
import { isDomainValid } from 'utils/isDomainValid'
import { urlProtocol } from 'utils/environment'
import { buildImpactMetadata } from 'features/Block/impact'
import { getBlocksMap } from 'features/Block/utils/getBlocksMap'
import { addDonationBlockProps } from 'features/Block/donation/donation'
import { Block } from 'features/Block/block.model'
import { logger } from 'utils/logger'
import { getDesignationData, toProgramDesignation } from 'services/designations'
import { DesignationDto } from 'models/designations'
import { useIntelligentAskAmount } from 'hooks/useIntelligentAskAmount'
import { getFooter, addFooterBlockProps } from 'features/Block/footer/footer'
import { getPageHeader, addPageHeaderBlockProps } from 'features/Block/page-header/page-header'
import Cookies from 'cookies'
import { addLayoutBlockProps } from 'features/Block/layout/layout'
import { addActivityFeedProps } from 'features/Block/activity-feed/activity-feed'

interface DonationPageProps extends CampaignPageProps {}

interface DonationPageQuery extends ParsedUrlQuery {
  mock?: string
  delay?: string
  campaignId: string
  pageId: string
}

const DonationPage: NextPage<DonationPageProps> = ({
  pageData,
  sharedBlocks,
  theme,
  pageConfig,
  metadata,
  isAnalyticsLoaded,
}) => {
  const currentUrl = `${urlProtocol}://${pageConfig.currentDomain}${usePathname()}`

  useIntelligentAskAmount(metadata, pageConfig.heapCookie)

  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href={pageConfig?.orgDomainMaskFavicon ?? '/favicon.ico'}
          sizes="any"
        />
        <title>{getPageTitle(PAGE_TYPES.DONATION, pageConfig)}</title>
        <meta
          name="description"
          content={getPageMetaDescription(PAGE_TYPES.DONATION, pageConfig)}
        />
      </Head>
      <OpenGraphTags
        facebookText={pageConfig.openGraphTagFacebookText || ''}
        facebookImageUrl={pageConfig.openGraphTagFacebookImageUrl || ''}
        orgName={pageConfig.orgName}
        currentUrl={currentUrl}
      />
      <CampaignPage
        theme={theme}
        pageData={pageData}
        sharedBlocks={sharedBlocks}
        pageConfig={pageConfig}
        metadata={metadata}
        isAnalyticsLoaded={isAnalyticsLoaded}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const { query, req, res, resolvedUrl } = context
  const { campaignId, delay = '0', mock, skipDomainMaskRedirect } = query as DonationPageQuery

  logger('trace', 'Start compiling donation page', {
    context: { campaignId, query, resolvedUrl },
  })

  try {
    if (mock) {
      const { theme, sharedBlocks, pageData } = await getMockData(
        mock,
        Number(delay),
        campaignId,
        PAGE_TYPES.DONATION,
      )

      const { pageConfig, metadata } = await getMockPageConfig(campaignId, PAGE_TYPES.DONATION)

      return {
        props: {
          theme,
          sharedBlocks,
          pageData,
          pageConfig,
          metadata,
        },
      }
    }

    /**
     * - Fetch the raw campaign data
     * - Fetch the donation page
     */
    const published = true
    const [campaign, campaignPages] = await Promise.all([
      getCampaignData({ campaignId, req, published }),
      getCampaignPagesData({ campaignId, req, published }),
    ])

    const [organization, domainMask, dataUseRestrictionTag] = await Promise.all([
      getOrganizationData({
        organizationId: campaign.organization_id,
        req,
      }),
      getPrimaryDomain({
        organizationId: campaign.organization_id,
        req,
      }),
      getOrganizationTags({
        organizationId: campaign.organization_id,
        filter: 'name=data-use-restriction',
        req,
      }),
    ])

    const domain = getDomainFromRequest(req)
    const hostUrl = `${urlProtocol}://${domain}`

    if (!isDomainValid(domain, domainMask)) {
      logger('error', new Error('Invalid domain or domain mask'), {
        context: { campaignId, query, domain, domainMask },
      })

      return {
        notFound: true,
      }
    }

    // When we implement Cloudflare caching on our pages, we will likely need to put in special rule
    // to specifically cache redirects (i.e. 308s) as Cloudflare does not cache certain redirects by
    // default: https://developers.cloudflare.com/cache/how-to/configure-cache-status-code
    if (!skipDomainMaskRedirect) {
      const redirect = domainMaskRedirect({
        currentDomain: domain,
        domainMask,
        url: resolvedUrl,
      })
      if (redirect) {
        return redirect
      }
    }

    if (isCampaignInactive(campaign)) {
      logger('warn', new Error('Campaign is inactive'), {
        context: {
          campaignId,
          externalUrl: campaign?.campaign_data?.external_url,
          organizationUrl: organization?.url,
        },
      })

      return {
        notFound: !campaign?.campaign_data?.external_url && !organization?.url,
        redirect: {
          permanent: false,
          destination: campaign?.campaign_data?.external_url || organization?.url,
        },
      }
    }

    const { theme, sharedBlocks } = campaignPages

    const donationPage = findCampaignPage(campaignPages, PAGE_TYPES.DONATION)
    if (!donationPage) {
      const error = new Error('Unable to find donation page in campaignPages data')
      logger('error', error, { context: { campaignId, campaignPages } })
      throw error
    }

    let donationPageData: Block
    try {
      donationPageData = denormalizeLayoutWithBlocks(donationPage)
    } catch (e) {
      const error = new Error('Unable to denormalize donation page data', { cause: e })
      logger('error', error, {
        context: { campaignId, blocks: donationPage.blocks, layout: donationPage.layout },
      })
      throw error
    }

    const getCheckoutBaseUrl = () => {
      if (domainMask) {
        return hostUrl
      }

      return `https://${process.env.CLASSY_STUDIO_DOMAIN}`
    }

    // IAA needs the Heap User ID from the Heap Cookie
    const cookies = new Cookies(req, res)
    let heapCookie

    try {
      heapCookie = cookies.get(`_hp2_id.${process.env.HEAP_APP_ID}`)
      if (!heapCookie) throw new Error()
      heapCookie = JSON.parse(decodeURIComponent(heapCookie))
    } catch {
      // IAA will return real time data if the Heap User Id is invalid / does not exist
      heapCookie = {}
    }
    // If an org has a data use restriction tag, make sure IAA is set to false in the metadata
    const dataUseRestriction = dataUseRestrictionTag?.data?.length === 1

    /**
     * Whammy-specific metadata (not injected into block data). All attributes MUST
     * have a value as any props returned by getServerSideProps will be serialized
     * and undefined values will throw an error.
     *
     * * Note, "pageConfig" is a temporary name. Rename it back to "metadata" once all
     * * block metadata has been migrated to block props.
     */
    const pageConfig: PageConfig = {
      analyticsServiceSettings: campaign?.modules?.analytics_service_settings ?? [],
      campaignId,
      campaignName: campaign.campaign_data.name,
      campaignCreatedAt: campaign.created_at,
      campaignRawCurrencyCode: campaign.campaign_data.raw_currency_code,
      campaignRawGoal: campaign.campaign_data.raw_goal,
      defaultDesignationId: campaign.campaign_data.designation_id,
      checkoutBaseUrl: getCheckoutBaseUrl(),
      currentDomain: domain,
      forwardCheckoutQueryParams: getCheckoutQueryParamsToForward(query),
      heapCookie,
      isCartEnabled: campaign?.checkout?.cart?.is_enabled ?? false,
      orgDomainMaskFavicon: domainMask?.favicon ?? '',
      orgId: campaign.organization_id,
      orgName: organization.name,
    }

    // * Block-specific metadata (deprecated, CL-28526)
    const metadata: Metadata = {
      campaignId, // Donation, Impact, checkoutUrlBuilder()
      isCartEnabled: campaign?.checkout?.cart?.is_enabled ?? false, // Donation, Imapct
      dcfEnabled: campaign.campaign_data.dcf_enabled, // DonationController/Donation
      orgId: campaign.organization_id, // Impact, checkoutUrlBuilder()
      donation: {
        checkoutBaseUrl: getCheckoutBaseUrl(),
      },
      /**
       * If one of use_intelligent_ask_recurring or use_intelligent_ask_onetime is enabled,
       * we will want to fetch IAA base amounts
       *
       * If one is enabled and one disabled, the response from IAA would look like:
       * { suggested_donation_amount: number, suggested_recurring_amount: null}
       */
      intelligentAskAmount: {
        onetimeEnabled:
          !dataUseRestriction && (campaign?.campaign_data?.use_intelligent_ask_onetime || false),
        recurringEnabled:
          !dataUseRestriction && (campaign?.campaign_data?.use_intelligent_ask_recurring || false),
      },
    }

    // * Adjust shared blocks

    const pageHeaderBlock = getPageHeader(sharedBlocks)
    if (pageHeaderBlock) {
      addPageHeaderBlockProps(pageHeaderBlock.props, organization)
    }

    const footerBlock = getFooter(sharedBlocks)
    if (footerBlock) {
      addFooterBlockProps(footerBlock.props, organization, campaign)
    }

    // * Adjust non-shared blocks

    const blocksMap = getBlocksMap(donationPageData)

    if (blocksMap['activity-feed']) {
      // ! Assumes a campaign page will only have a single activity feed block
      const activityFeed = blocksMap['activity-feed'][0]
      // Force narrowing type (AnyBlockProps -> ActivityFeedProps)
      addActivityFeedProps(activityFeed.props as ActivityFeedProps, campaignId)
    }

    // TODO added per CL-39568, clean up with CL-40328
    blocksMap.layout?.forEach((layoutBlock) => {
      addLayoutBlockProps(layoutBlock.props as LayoutProps, campaignPages.schema_version)
    })

    if (blocksMap['social-links'] || blocksMap['share-button']) {
      // TODO CL-28526, pass props to SocialLinks and/or ShareButton
      metadata.socialShareLinks = buildSocialShareLinks(campaign, domainMask?.value)

      pageConfig.openGraphTagFacebookText = metadata.socialShareLinks.facebookText || ''
      pageConfig.openGraphTagFacebookImageUrl = metadata.socialShareLinks.facebookImageUrl || ''
    }

    if (blocksMap.donation || blocksMap.impact) {
      // TODO CL-28526, pass this as a prop to Donation and Impact
      metadata.forwardCheckoutQueryParams = getCheckoutQueryParamsToForward(query)
      metadata.minDonationAmount = campaign?.campaign_data?.minimum_donation_amount ?? 1
    }

    blocksMap.donation?.forEach((donationBlock) => {
      // Force narrowing type (AnyBlockProps -> DonationProps)
      addDonationBlockProps(donationBlock.props as DonationProps, query)
    })

    let defaultProgramDesignationId: number | null = null
    let defaultProgramDesignation: ProgramDesignation = {} as ProgramDesignation
    try {
      /**
       * Under Settings > Program Designations, the value for sort_designation_by is saved at the
       * legacy APIv2 /campaigns/{id} end point.
       *
       * https://docs.classy-test.org/apiv2/#tag/Campaign/operation/fetchCampaign
       *
       * Then fetch the campaign's sorted program designation group by sort_designation_by.
       * Assumes designation_id is the campaign's default program designation.
       */
      const { designation_id, sort_designation_by } = await getLegacyCampaignData({
        campaignId,
        req,
      })

      defaultProgramDesignationId = designation_id

      const designationData = await getDesignationData({
        designationId: designation_id,
        req,
      })

      const designationDataJson: DesignationDto = await designationData.json()
      defaultProgramDesignation = toProgramDesignation(designationDataJson)

      if (blocksMap.donation && metadata.donation) {
        // TODO CL-28526, pass props to each Donation
        metadata.donation.defaultProgramDesignation = defaultProgramDesignation
      }

      if (blocksMap.impact) {
        // TODO CL-28526, pass props to each Impact
        const impactMetadata = await buildImpactMetadata(
          blocksMap.impact,
          sort_designation_by,
          defaultProgramDesignation,
          req,
          campaignId,
        )

        metadata.impact = impactMetadata
      }
    } catch (e) {
      const error = new Error('Unable to apply program designations to blocks', { cause: e })
      logger('error', error, {
        context: { defaultProgramDesignationId, defaultProgramDesignation },
      })

      throw error
    }

    let orgChannelsData: Channel[] = []
    try {
      /**
       * Get the organization's channels data and add to campaign.modules
       */
      const { data: channels } = await getOrganizationChannels(campaign.organization_id as string)
      if (channels) {
        orgChannelsData = [...channels]
      }

      pageConfig.channelsSettings = orgChannelsData
    } catch (e) {
      const error = new Error('Unable to fetch getOrganizationChannels data', { cause: e })
      const orgId = campaign.organization_id
      logger('error', error, {
        context: { orgId, campaignId },
      })
    }

    // * Set CloudFlare cache-tag
    res.setHeader(
      'Cache-Tag',
      `campaign-${campaignId}-${PAGE_TYPES.DONATION},campaign-${campaignId},organization-${campaign.organization_id}`,
    )

    logger('trace', 'Successfully compiled donation page', {
      context: { campaignId, pageConfig, metadata },
    })

    return {
      props: {
        theme,
        sharedBlocks,
        pageData: donationPageData,
        pageConfig,
        metadata,
      },
    }
  } catch (e) {
    logger('error', new Error('Unable to compile donation page', { cause: e }), {
      context: { campaignId, query, resolvedUrl },
    })

    return {
      notFound: true,
    }
  }
}

export default DonationPage
